body {
  display: flex;
  margin: 0;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

#root {
  display: flex;
  flex: 1;
}
